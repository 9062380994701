//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
  props: {
    location: {
      type: Object,
      default: () => {}
    },
    orderType: {
      type: String,
      default: 'pickup'
    }
  },
  computed: {
    ...mapState([ 'order', 'options' ])
  },
  methods: {
    async startOrder() {
      this.$store.commit('setLocation', this.location)
      await this.$api.getMenu()
      let order = null
      if(this.order) {
        order = await this.$api.transferOrder()
        if(!order) {
          order = await this.$api.createOrder()
        }
        order = await this.$api.setHandoff('pickup')
        if(order && order.totals.tip > 0) {
          await this.$api.addTip(0)
        }
      } else {
        order = await this.$api.createOrder()
      }
      if(order) {
        if(this.orderType == 'delivery') {
          let order2 = await this.$api.setAddress(this.$store.state.customer.delivery)
          if(order2) {
            this.$router.push('/order/')
          }
        } else {
          await this.$api.setHandoff('pickup')
          this.$router.push('/order/')
        }
      }
    },
    setStore() {
      this.$store.commit('setLocation', this.location)
      this.$store.commit('setOrder', null)
      this.$router.push('/menu/')
    }
  }
}
